// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';
// import translationEN from './languages/en/translation.json';
// import translationAR from './languages/ar/translation.json';

// i18n
//   .use(initReactI18next)
//   .init({
//     resources: {
//       en: {
//         translation: translationEN,
//       },
//       ar: {
//         translation: translationAR,
//       },
//     },
//     lng: 'en', // default language
//     fallbackLng: 'en',
//     interpolation: {
//       escapeValue: false,
//     },
//   });

// export default i18n;


import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './languages/en/translation.json';
import translationAR from './languages/ar/translation.json';

// Get the saved language from local storage or default to 'en'
const savedLanguage = localStorage.getItem('language') || 'en';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      ar: {
        translation: translationAR,
      },
    },
    lng: savedLanguage, // Set the initial language based on the saved language
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

import React from 'react';
import { useState,useEffect } from 'react';
import './whatsppChat.css'
import whatasppChat from '../../Assets/Images/icon_whatsapp.png'

const WhatsAppLink = ({ phoneNumber }) => {
//   const handleWhatsAppClick = () => {
//     const url = `https://wa.me/${phoneNumber}`;
//     window.open(url, '_blank');
//   };

//   return (
//     <button onClick={handleWhatsAppClick}>
//       {/* <img src="whatsapp-icon.png" alt="WhatsApp Icon" /> */}
//       CHAT ME IN WHATSAPP
//     </button>
//   );
// };

const [isVisible, setIsVisible] = useState(false);

useEffect(() => {
  const handleScroll = () => {
    if (window.scrollY > 200) { // Adjust this value as needed based on your layout
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);

const handleWhatsAppClick = () => {
  const url = `https://wa.me/${phoneNumber}`;
  window.open(url, '_blank');
};

return (
  <div className={`whatsapp-icon ${isVisible ? 'visible' : 'visible'}`} style={{height:'35%'}} onClick={handleWhatsAppClick}>
    <img src={whatasppChat} alt="WhatsApp Icon" style={{height:60}} />
  </div>
);
};


export default WhatsAppLink;

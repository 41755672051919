// import logo from "./logo.svg";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "./App.css";
// import Header from "../src/Components/Header/Header";
// import Router from "../src/Router";
// import Footer from "../src/Components/Footer/Footer";
// import { useTranslation } from "react-i18next";
// import LanguageSwitcher from "./Components/LanguageSwitcher/LanguageSwitcher";
// import "./i18n"; // Ensure this is imported to initialize i18next

// import { Helmet, HelmetProvider } from "react-helmet-async";
// function App() {
//   const { t } = useTranslation();

  
//   return (
//     <HelmetProvider>
//       <div>
//         <div>
//           <Helmet>
//             <meta
//               name="viewport"
//               content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
//             />
//           </Helmet>
//         </div>
//         <Header />
//         <LanguageSwitcher />

//         <Router />
//         <Footer />
//       </div>
//     </HelmetProvider>
//   );
// }

// export default App;


import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Header from "./Components/Header/Header";
import Router from "./Router";
import Footer from "./Components/Footer/Footer";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./Components/LanguageSwitcher/LanguageSwitcher";
import "./i18n"; // Ensure this is imported to initialize i18next
import { Helmet, HelmetProvider } from "react-helmet-async";
import i18n from './i18n'; // Import the i18n instance
import ScrollToTopButton from "./Components/ScrollToTopButton/ScrollToTopButton";

function App() {
  const { t } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, []);

  return (
    <HelmetProvider>
      <div>
        <div>
          <Helmet>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />
          </Helmet>
        </div>
        <Header />
        <Router />
        <Footer />
        <ScrollToTopButton />
      </div>
    </HelmetProvider>
  );
}

export default App;

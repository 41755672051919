// import React from "react";
// import "./ourWorks.css";
// import workImage from '../../Assets/Images/workimagedemo.png'

// const OurWork = () => {
//   return (
//     <div>

//       <section id="team" className="team section-bg">
//         <div className="container" data-aos="fade-up">
//           <div
//             className="section-title"
//             style={{ margin: 50, textAlign: "center" }}
//           >
//             <h2 style={{ color: "black", backgroundColor: "white" }}>
//               {" "}
//               OUR WORKS
//             </h2>
//             <hr style={{ color: "black" }}></hr>
//           </div>
//           <div className="row">
//             <div className="col-lg-3" style={{ marginBottom: 15 }}>
//               <div className="member" style={{borderRadius:40}}>
//                 <div
//                   className="pic"
//                   style={{
//                     width: 150,
//                     marginLeft: 35,
//                     height: 150,
//                     marginTop: -8,
//                   }}
//                 >
//                   <img src={workImage} alt="" className="img-fluid" />
//                 </div>
//                 <div style={{ marginLeft: 50 }}>
//                   <h4> 123</h4>
//                   <span>
//                     <a
//                       href="https://badar.qa/"
//                       target="blank"
//                       style={{ textDecoration: "none", color:'black' }}
//                     >
//                       view more
//                     </a>
//                   </span>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-3" style={{ marginBottom: 15 }}>
//               <div className="member" style={{borderRadius:40}}>
//                 <div
//                   className="pic"
//                   style={{
//                     width: 150,
//                     marginLeft: 35,
//                     height: 150,
//                     marginTop: -8,
//                   }}
//                 >
//                   <img src={workImage} alt="" className="img-fluid" />
//                 </div>
//                 <div style={{ marginLeft: 50 }}>
//                   <h4>123 </h4>
//                   <span>
//                     <a
//                       href="https://badar.qa/"
//                       target="blank"
//                       style={{ textDecoration: "none", color:'black' }}
//                     >
//                       view more
//                     </a>
//                   </span>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-3" style={{ marginBottom: 15 }}>
//               <div className="member"  style={{borderRadius:40}}>
//                 <div
//                   className="pic"
//                   style={{
//                     width: 150,
//                     marginLeft: 35,
//                     height: 150,
//                     marginTop: -8,
//                   }}
//                 >
//                   <img src={workImage} alt="" className="img-fluid" />
//                 </div>
//                 <div style={{ marginLeft: 50 }}>
//                   <h4>123 </h4>
//                   <span>
//                     <a
//                       href="https://badar.qa/"
//                       target="blank"
//                       style={{ textDecoration: "none", color:'black' }}
//                     >
//                       view more
//                     </a>
//                   </span>
//                 </div>
//               </div>
//             </div>

//             <div className="col-lg-3" style={{ marginBottom: 15 }}>

//               <div className="member"style={{borderRadius:40}}>
//                 <div
//                   className="pic"
//                   style={{
//                     width: 150,
//                     marginLeft: 35,
//                     height: 150,
//                     marginTop: -8,
//                   }}
//                 >
//                   <img src={workImage} alt="" className="img-fluid" />
//                 </div>
//                 <div style={{ marginLeft: 50 }}>
//                   <h4>123 </h4>
//                   <span>
//                     <a
//                       href="https://badar.qa/"
//                       target="blank"
//                       style={{ textDecoration: "none", color:'black' }}
//                     >
//                       view more
//                     </a>
//                   </span>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default OurWork;

// import React from "react";
// import "./ourWorks.css";
// import workImage from "../../Assets/Images/ourworkdemoimage.jpg";

// const OurWork = () => {
//   return (
//     <div>
//       <section id="ourWork" className="ourWork section-bg">
//         <div className="container" data-aos="fade-up">
//           <div
//             className="section-title"
//             style={{ margin: 50, textAlign: "center" }}
//           >
//             <h2 style={{ color: "black", backgroundColor: "white" }}>
//               OUR WORKS
//             </h2>
//             <hr style={{ color: "black" }}></hr>
//           </div>
//           <div className="row">
//             {[...Array(4)].map((_, index) => (
//               <div
//                 key={index}
//                 className="col-lg-3"
//                 style={{ marginBottom: '2rem', height: "15rem" }}
//               >
//                 <a
//                   href="https://badar.qa/"
//                   target="blank"
//                   //  className="card-link"
//                   style={{ textDecoration: "none" }}
//                 >
//                   <div
//                     className="member"
//                     style={{
//                       backgroundImage: `url(${workImage})`,
//                       position: "relative",
//                       backgroundSize: "cover",
//                       backgroundRepeat: "no-repeat",
//                       backgroundPosition: "center",
//                       boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.1)",
//                       padding: "30px",
//                       borderRadius: "10%",
//                       transition: "0.5s",
//                       height: "100%",
//                       display: "flex",
//                       flexDirection: "column",
//                       justifyContent: "flex-end",
//                       overflow: "hidden",
//                       color: "white",
//                       textAlign: "center",
//                       alignItems:'center'
//                     }}
//                   >
//                     {/* <div
//                       className="member-info"

//                     >
//                       <h4>123</h4>
//                       <span
//                         className="link-button"
//                         style={{
//                           display: "block",
//                           fontSize: 15,
//                           paddingBottom: 5,
//                           fontWeight: 500,
//                           color: "white",
//                           backgroundColor: "rgb(8, 169, 233)",
//                           borderRadius: 10,
//                           textAlign: "center",
//                         }}
//                       >
//                         <a
//                           href="https://badar.qa/"
//                           target="blank"
//                           style={{ textDecoration: "none" }}
//                         >
//                           view more
//                         </a>
//                       </span>
//                     </div> */}
//                      <div
//                     className="member-info"

//                   >

//                     <span
//                       className="link-button"
//                       style={{
//                         display: "block",
//                         fontSize: 15,
//                         paddingBottom: 5,
//                         fontWeight: 500,
//                         color: "white",
//                         backgroundColor: "rgb(8, 169, 233)",
//                         borderRadius: 10,
//                         textAlign: "center",
//                       }}
//                     >
//                       <a
//                         href="https://badar.qa/"
//                         target="_blank"
//                         style={{
//                           textDecoration: "none",
//                           color: "white",
//                           display: "block",
//                           padding: "5px 10px",
//                         }} rel="noreferrer"
//                       >
//                         view more
//                       </a>
//                     </span>
//                   </div>
//                   </div>
//                 </a>
//               </div>
//             ))}
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default OurWork;

import React from "react";
import "./ourWorks.css";

import workImage2 from "../../Assets/Images/icon_website.png";
import workImage3 from "../../Assets/Images/icon_online_ store.png";
import workImage4 from "../../Assets/Images/icon_ecommerce.png";
import { useTranslation } from "react-i18next";


const imageArray = [workImage2, workImage3, workImage4];

const OurWork = () => {
  const { t } = useTranslation();

  return (
    <div>
      <section id="ourWork" className="ourWork section-bg">
        <div className="container" data-aos="fade-up">
          <div
            className="section-title"
            style={{ margin: 50, textAlign: "center" }}
          >
            <h2 style={{ color: "black", backgroundColor: "white" }}>
              {t('home.our_work')}
            </h2>
            <hr style={{ color: "black" }}></hr>
          </div>
          <div className="row" style={{ justifyContent: "center" }}>
            {imageArray.map((image, index) => (
              <div
                key={index}
                className="col-lg-3"
                style={{ marginBottom: 15, height: "15rem" }}
              >
                <a
                  href="http://qatar.nasscriprt.com/"
                  target="_blank"
                  style={{ textDecoration: "none", color: "inherit" }}
                  rel="noreferrer"
                >
                  <div
                    className="member"
                    style={{
                      backgroundImage: `url(${image})`,
                      position: "relative",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.1)",
                      padding: "30px",
                      borderRadius: "10%",
                      transition: "0.5s",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      overflow: "hidden",
                      color: "white",
                      textAlign: "center",
                      alignItems: "center",
                      backgroundColor: "black",
                    }}
                  >
                    <div className="member-info">
                      <span
                        className="link-button"
                        style={{
                          display: "block",
                          fontSize: 15,
                          paddingBottom: 5,
                          fontWeight: 500,
                          color: "black",
                          backgroundColor: "white",
                          borderRadius: 10,
                          textAlign: "center",
                        }}
                      >
                        <a
                          href="http://qatar.nasscriprt.com/"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "black",
                            display: "block",
                            // padding: "5px 10px",
                          }}
                          rel="noreferrer"
                        >
                          {t('home.view_more')}
                        </a>
                      </span>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default OurWork;

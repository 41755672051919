// import React from "react";
// import { Link, useLocation } from "react-router-dom";
// import "./Header.css";
// import { Container, Row, Col, Form } from "react-bootstrap";
// import logo from "../../Assets/Images/Nlogo_White_footage.svg";
// import { IoIosArrowDown } from "react-icons/io";
// import menu from "../../Assets/Images/Menuicon.svg";
// import menuWhite from "../../Assets/Images/menu icons.svg";

// import logo1 from "../../Assets/Images/Nlogo_black_s.svg";

// import { useState } from "react";

// function Header() {
//   const [sidebar, setSidebar] = useState(false);
//   const showSidebar = () => setSidebar(!sidebar);
//   const menuItems =[
//     {
//       id:12,
//       title:"Home",
//       link:"/",
//     },
//     {
//       id:23,
//       title:"About",
//       link:"/about",
//     },
//     {
//       id:34,
//       title:"Services",
//       link:"/services",
//     },
//     {
//       id:35,
//       title:"Contact Us",
//       link:"/contactus",
//     },

//   ]
//   return (
//     <div className="head">
//       <div className="header">
//         <div style={{ display: "flex", justifyContent: "space-between" }}>
//           <div xs={12} md={3} lg={4}>
//             <Link className="menuLink" to={"/"}>
//               <img
//                 src={useLocation().pathname == "/" ? logo1 : logo}
//                 style={{ width: 40 }}
//               />
//             </Link>
//           </div>
//           <div xs={12} md={9} lg={8}></div>
//           <div>
//             <img
//               onClick={showSidebar}
//               className="menucls"
//               src={useLocation().pathname == "/" ? menu : menuWhite}
//               style={{ width: 35 }}
//             />
//           </div>
//         </div>
//       </div>
//       <div className={sidebar ? "menu active" : "menu"}>
//         <div className="menuitem" onClick={showSidebar}>
//           <div>
//             <img
//               className="close-button"
//               src={menuWhite}
//               style={{ width: 35 }}
//             />
//           </div>
//           {menuItems.map((menuBar)=>{
//             return(
//             <div key={menuBar.id} className={sidebar ? "hascbpl active" : "hascbpl"}>
//               <Link className="menuLink" to={menuBar.link}>
//                 {menuBar.title}
//               </Link>
//             </div>)

//           })}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Header;

import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Header.css";
import logo from "../../Assets/Images/Nlogo_White_footage.svg";
import logo1 from "../../Assets/Images/Nlogo_black_s.svg";
import menu from "../../Assets/Images/Menuicon.svg";
import menuWhite from "../../Assets/Images/menu icons.svg";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";

function Header() {
  const { t } = useTranslation();
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);

  const menuItems = [
    {
      id: 12,
      title: t("header.home"),
      link: "/",
    },
    {
      id: 23,
      title: t("header.about"),
      link: "/about",
    },

    {
      id: 25,
      title: t("header.our_solution_partners"),
      link: "/ourPartners",
    },
    {
      id: 34,
      title: t("header.services"),
      link: "/services",
    },
    {
      id: 35,
      title: t("header.contact_us"),
      link: "/contactus",
    },
  ];

  return (
    <div className="head">
      <div className="header">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div xs={12} md={3} lg={4}>
            <Link className="menuLink" to={"/"}>
              <img
                src={useLocation().pathname === "/" ? logo1 : logo}
                style={{ width: 40 }}
                alt="Logo"
              />
            </Link>
          </div>
          <div xs={12} md={9} lg={8}></div>
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "16px" }}>
              <LanguageSwitcher />
            </div>
            <img
              onClick={showSidebar}
              className="menucls"
              src={useLocation().pathname === "/" ? menu : menuWhite}
              style={{ width: 35 }}
              alt="Menu Icon"
            />
          </div>
        </div>
      </div>

      <div className={sidebar ? "menu active" : "menu"}>
        <div className="menuitem" onClick={showSidebar}>
          <div>
            <img
              className="close-button"
              src={menuWhite}
              style={{ width: 35 }}
              alt="Close Menu Icon"
            />
          </div>
          {menuItems.map((menuBar) => (
            <div
              key={menuBar.id}
              className={sidebar ? "hascbpl active" : "hascbpl"}
            >
              <Link className="menuLink" to={menuBar.link}>
                {menuBar.title}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Header;

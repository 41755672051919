// import React from "react";
// import "./Footer.css";
// import { Container, Row, Col, Form } from "react-bootstrap";
// import logo1 from "../../Assets/Images/Nlogo_black_s.svg";
// import menu from "../../Assets/Images/Menuicon.svg";
// import Gradient1 from "../../Assets/Images/Gradient1.svg";
// import vector1 from "../../Assets/Images/Vector 1.svg";
// import ellipse1 from "../../Assets/Images/Ellipse 1.svg";
// import gradient2 from "../../Assets/Images/Gradient2.svg";
// import client from "../../Assets/Images/Clients.png";
// import left from "../../Assets/Images/Client_Left arrow.svg";
// import right from "../../Assets/Images/Client_Right arrow.svg";
// import logo2 from "../../Assets/Images/Nlogo_White_footage.svg";
// import { FiPhone } from "react-icons/fi";
// import { FaWhatsapp, FaInstagram } from "react-icons/fa";
// import {
//   TiSocialTwitterCircular,
//   TiSocialLinkedinCircular,
// } from "react-icons/ti";
// import { RiFacebookCircleLine } from "react-icons/ri";
// import { BiCopyright } from "react-icons/bi";
// import WhatsAppLink from "../whatsappChat/whatsappChat";
// function Footer() {
//   return (
//     <div>
//       <div className="black">
//         <Row>
//           <Col xs={12} md={9}>
//             <div className="boot" style={{ marginTop: 40 }}>
//               <div
//                 className="stay"
//                 style={{ color: "white", fontSize: "50px", fontWeight: 700 }}
//               >
//                 Stay in Touch{" "}
//                 <span
//                   style={{
//                     color: "#32cd32",
//                     fontSize: "50px",
//                     fontWeight: 800,
//                   }}
//                 >
//                   .
//                 </span>
//               </div>
//               <div
//                 className="why why1"
//                 style={{ color: "white", fontSize: "40px" }}
//               >
//                 NASSCRIPT Software Innovation W.L.L
//               </div>
//             </div>
//           </Col>
//           <Col xs={12} md={3}>
//             <div
//               className="logo222"
//               style={{
//                 marginTop: 45,
//                 display: "flex",
//                 justifyContent: "flex-end",
//               }}
//             >
//               <img className="logo22" src={logo2} style={{ width: 100 }} />
//             </div>
//           </Col>
//         </Row>
//         <div className="foot">
//           <Row style={{ justifyContent: "space-between" }}>
//             <Col xs={12} md={5}>
//               <div style={{ color: "white", marginTop: 20, fontSize: 15 }}>
//                 <div className="why">HEAD OFFICE</div>
//                 <div style={{ marginTop: 15, fontWeight: "medium" }}>Qatar</div>
//                 <div className="why" style={{ marginTop: 5 }}>
//                   NASScript Software Innovations W.L.L,
//                 </div>
//                 <div className="why">Doha, Qatar</div>
//                 <div style={{ display: "flex" }}>
//                   <FiPhone size={18} style={{ marginTop: 17 }} />
//                   <div className="why" style={{ marginTop: 15, marginLeft: 7 }}>
//                     +974 31062060
//                   </div>
//                 </div>
//               </div>
//               {/* 
// 									<div  style={{color:'white', marginTop:20, fontSize:15}}>
// 									<div className='why'>HEAD OFFICE</div>
// 									<div style={{marginTop:15, fontWeight:'medium'}}>India</div>
// 									<div className='why' style={{marginTop:5}}>NASScript Software Innovations Pvt Ltd,</div>
// 									<div className='why'>pattambi</div>
// 									<div className='why'>Palakkad,Kerala</div>
// 									<div className='why'>679533</div>
								
// 									<div style={{display:'flex'}}>
// 										<FiPhone size={18} style={{marginTop:17}}/>
// 										<div className='why' style={{marginTop:15, marginLeft:7}}>+974 31602060</div>
// 									</div>
// 									<div style={{display:'flex'}}>
// 										<FaWhatsapp size={18} style={{marginTop:17}}/>
// 										<div className='why' style={{marginTop:15, marginLeft:7}}>+91 9745151015</div>
// 									</div>
// 									</div> */}
//             </Col>
//             {/* <Col xs={12} md={4}>
// 									<div style={{color:'white', marginTop:20, fontSize:15}}>
// 									<div className='why'>BRANCH OFFICE</div>
// 									<div style={{marginTop:15, fontWeight:'medium'}}>Qatar</div>
// 									<div className='why' style={{marginTop:5}}>NASScript Software Innovations W.L.L,</div>
// 									<div className='why'>Doha, Qatar</div>
// 									<div style={{display:'flex'}}>
// 										<FiPhone size={18} style={{marginTop:17}}/>
// 										<div className='why' style={{marginTop:15, marginLeft:7}}>+974 31602060</div>
// 									</div>
// 									</div>
// 								</Col> */}
//             <Col xs={12} md={3}>
//               <div
//                 className="elements"
//                 style={{
//                   color: "white",
//                   marginTop: 20,
//                   fontSize: 15,
//                   float: "right",
//                 }}
//               >
//                 <a className="link-btn" href="/">
//                   <div className="why" style={{ marginTop: 15 }}>
//                     Home
//                   </div>
//                 </a>
//                 <a className="link-btn" href="/about">
//                   <div className="why" style={{ marginTop: 15 }}>
//                     About
//                   </div>
//                 </a>
//                 <a className="link-btn" href="/services">
//                   <div className="why" style={{ marginTop: 15 }}>
//                     Services
//                   </div>
//                 </a>
//                 <a className="link-btn" href="/contactus">
//                   <div className="why" style={{ marginTop: 15 }}>
//                     Careers
//                   </div>
//                 </a>
//                 <a className="link-btn" href="/contactus">
//                   <div className="why" style={{ marginTop: 15 }}>
//                     Contact
//                   </div>
//                 </a>
//               </div>
// 			  <div className=""  style={{  }}>

// 			  <WhatsAppLink phoneNumber="+97431602060" />
// 			  </div>

//             </Col>
//           </Row>
//           <Row>
//             <div
//               style={{
//                 marginTop: 25,
//                 display: "flex",
//                 justifyContent: "center",
//               }}
//             >
//               <FaInstagram size={20} style={{ color: "white" }} />
//               <RiFacebookCircleLine
//                 size={22}
//                 style={{ color: "white", marginLeft: 8 }}
//               />
//               <TiSocialTwitterCircular
//                 size={23}
//                 style={{ color: "white", marginLeft: 8 }}
//               />
//               <TiSocialLinkedinCircular
//                 size={23}
//                 style={{ color: "white", marginLeft: 8 }}
//               />
//             </div>
//             <div
//               style={{
//                 color: "white",
//                 marginTop: 30,
//                 fontSize: 12,
//                 display: "flex",
//                 justifyContent: "center",
//               }}
//             >
//               <div>
//                 <BiCopyright
//                   size={13}
//                   style={{ marginRight: 3, marginBottom: 2 }}
//                 />
//               </div>
//               <div className="why">
//                 2022 NASSCRIPT. All right reserved. Terms and condition. privacy
//                 policy
//               </div>
//             </div>
//           </Row>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Footer;




import React, { useEffect, useState } from "react";
import "./Footer.css";
import { Container, Row, Col } from "react-bootstrap";
import logo2 from "../../Assets/Images/Nlogo_White_footage.svg";
import { FiPhone } from "react-icons/fi";
import { FaWhatsapp, FaInstagram } from "react-icons/fa";
import {
  TiSocialTwitterCircular,
  TiSocialLinkedinCircular,
} from "react-icons/ti";
import { RiFacebookCircleLine } from "react-icons/ri";
import { BiCopyright } from "react-icons/bi";
import WhatsAppLink from "../whatsappChat/whatsappChat";
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();

  const [currentYear, setCurrentYear] = useState('');

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    setCurrentYear(year);
  }, []);


  return (
    <div>
      <div className="black">
        <Row>
          <Col xs={12} md={9}>
            <div className="boot" style={{ marginTop: 40 }}>
              <div
                className="stay"
                style={{ color: "white", fontSize: "50px", fontWeight: 700 }}
              >
                {t('footer.stay_in_touch')}{" "}
                <span
                  style={{
                    color: "#32cd32",
                    fontSize: "50px",
                    fontWeight: 800,
                  }}
                >
                  .
                </span>
              </div>
              <div
                className="why why1"
                style={{ color: "white", fontSize: "40px" }}
              >
                {t('footer.company_name')}
              </div>
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div
              className="logo222"
              style={{
                marginTop: 45,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <img className="logo22" src={logo2} style={{ width: 100 }} />
            </div>
          </Col>
        </Row>
        <div className="foot">
          <Row style={{ justifyContent: "space-between" }}>
            <Col xs={12} md={5}>
              <div style={{ color: "white", marginTop: 20, fontSize: 15 }}>
                <div className="why">{t('footer.head_office')}</div>
                <div style={{ marginTop: 15, fontWeight: "medium" }}>{t('footer.qatar')}</div>
                <div className="why" style={{ marginTop: 5 }}>{t('footer.address')}</div>
                <div style={{ display: "flex" }}>
                  <FiPhone size={18} style={{ marginTop: 17 }} />
                  <div className="why" style={{ marginTop: 15, marginLeft: 7 }}>
                    {t('footer.phone')}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <FiPhone size={18} style={{ marginTop: 17 }} />
                  <div className="why" style={{ marginTop: 15, marginLeft: 7 }}>
                    {t('footer.phone2')}
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div
                className="elements"
                style={{
                  color: "white",
                  marginTop: 20,
                  fontSize: 15,
                  float: "right",
                }}
              >
                <a className="link-btn" href="/">
                  <div className="why" style={{ marginTop: 15 }}>
                    {t('footer.home')}
                  </div>
                </a>
                <a className="link-btn" href="www.nasscript.com/about">
                  <div className="why" style={{ marginTop: 15 }}>
                    {t('footer.about')}
                  </div>
                </a>
                <a className="link-btn" href="/services">
                  <div className="why" style={{ marginTop: 15 }}>
                    {t('footer.services')}
                  </div>
                </a>
                <a className="link-btn" href="/contactus">
                  <div className="why" style={{ marginTop: 15 }}>
                    {t('footer.careers')}
                  </div>
                </a>
                <a className="link-btn" href="/contactus">
                  <div className="why" style={{ marginTop: 15 }}>
                    {t('footer.contact')}
                  </div>
                </a>
              </div>
              <div className="">
                <WhatsAppLink phoneNumber="+97431602060" />
              </div>
            </Col>
          </Row>
          <Row>
            <div
              style={{
                marginTop: 25,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <FaInstagram size={20} style={{ color: "white" }} />
              <RiFacebookCircleLine
                size={22}
                style={{ color: "white", marginLeft: 8 }}
              />
              <TiSocialTwitterCircular
                size={23}
                style={{ color: "white", marginLeft: 8 }}
              />
              <TiSocialLinkedinCircular
                size={23}
                style={{ color: "white", marginLeft: 8 }}
              />
            </div>
            <div
              style={{
                color: "white",
                marginTop: 30,
                fontSize: 12,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div>
                <BiCopyright
                  size={13}
                  style={{ marginRight: 3, marginBottom: 2 }}
                />
              </div>
              <div className="why">
               {currentYear} {t('footer.rights_reserved')}
              </div>
            </div>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Footer;

